<template>
  <div class="margin-90">
    <router-link class="function-item between p10 mt20 align-warp" to="/blotter_list">
      <div class="font14">我的日程提醒</div>
      <van-icon name="arrow" color="#999999"/>
    </router-link>
    <router-link class="function-item between p10 mt20 align-warp" to="/affair_edit">
      <div class="font14">新增提醒</div>
      <van-icon name="arrow" color="#999999"/>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: "index"
  }
</script>

<style scoped>
  .function-item {
    color: #333333;
    line-height: 30px;
    border: 1px solid #f2f2f2;
    box-shadow: 2px 2px 1px #f4f4f4;
  }
</style>